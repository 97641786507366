import { Injectable } from "@angular/core";
import { CanActivate, Router, UrlTree } from "@angular/router";
import { MsalService } from "@azure/msal-angular";

import { PATH_REDIRECT_TO } from "../../routes";

@Injectable({ providedIn: "root" })
export class MustBeUnregister implements CanActivate {
	constructor(private msalService: MsalService, private router: Router) {}

	canActivate(): boolean | UrlTree {
		const accounts = this.msalService.instance.getAllAccounts();

		const isUnRegister = accounts.length === 0;

		return isUnRegister || this.router.parseUrl(PATH_REDIRECT_TO);
	}
}
