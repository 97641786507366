import { Injectable } from "@angular/core";

const IMPERSONATING_EMAIL = "impersonating-email";

@Injectable({
	providedIn: "root",
})
export class ImpersonatingStorageService {
	constructor() {}

	public saveImpersonatingMail(email: string): void {
		window.localStorage.removeItem(IMPERSONATING_EMAIL);
		window.localStorage.setItem(IMPERSONATING_EMAIL, email);
	}
	
	public getImpersonatingMail(): string | null {
		return window.localStorage.getItem(IMPERSONATING_EMAIL) || "";
	}

	public removeImpersonatingMail(): void {
		window.localStorage.removeItem(IMPERSONATING_EMAIL);
	}


}
