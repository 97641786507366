import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { LayoutLoggedInComponent } from "./layout-loggedin/layout-loggedin.component";

import {
	SLUG_LOGIN,
	PATH_REDIRECT_TO,
	SLUG_PROVIDER,
	SLUG_OPEN_CLOSE_EVENTS,
	PREFIX_PRIVATE_ROUTES,
} from "./routes";
import { MsalGuard } from "@azure/msal-angular";
import { MustBeUnregister } from "./core/guards/mustBeUnRegister.guard";

const routes: Routes = [
	{
		path: SLUG_LOGIN,
		canActivate: [MustBeUnregister],
		loadChildren: () =>
			import("./auth/auth.module").then((m) => m.AuthModule),
	},
	{
		path: PREFIX_PRIVATE_ROUTES,
		component: LayoutLoggedInComponent,
		canActivate: [MsalGuard],
		children: [
			{
				path: SLUG_PROVIDER,
				loadChildren: () =>
					import("./providers/providers.module").then(
						(m) => m.ProvidersModule
					),
			},
			{
				path: SLUG_OPEN_CLOSE_EVENTS,
				loadChildren: () =>
					import(
						"./opening-closing-events/opening-closing-events.module"
					).then((m) => m.OpeningClosingEventsModule),
			},
		],
	},
	{ path: "", pathMatch: "full", redirectTo: PATH_REDIRECT_TO },
	{
		path: "**",
		redirectTo: PATH_REDIRECT_TO,
		pathMatch: "full",
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			relativeLinkResolution: "legacy",
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
