import {
	MsalGuardConfiguration,
	MsalInterceptorConfiguration,
} from "@azure/msal-angular";
import {
	BrowserCacheLocation,
	InteractionType,
	IPublicClientApplication,
	LogLevel,
	PublicClientApplication,
} from "@azure/msal-browser";
import { environment } from "src/environments/environment";
import {
	AZURE_URI_POST_LOGOUT_REDIRECT_TO,
	AZURE_URI_REDIRECT_TO,
	AZURE_PATH_LOGIN_FAILURE,
} from "./routes";

export const isIE =
	window.navigator.userAgent.indexOf("MSIE ") > -1 ||
	window.navigator.userAgent.indexOf("Trident/") > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {}

export function MSALInstanceFactory(): IPublicClientApplication {
	return new PublicClientApplication({
		auth: {
			clientId: environment.AZURE_CLIENT_ID,
			authority: environment.AZURE_AUTHORITY,
			redirectUri: AZURE_URI_REDIRECT_TO,
			postLogoutRedirectUri: AZURE_URI_POST_LOGOUT_REDIRECT_TO,
		},
		cache: {
			cacheLocation: BrowserCacheLocation.LocalStorage,
			storeAuthStateInCookie: isIE,
		},
		system: {
			loggerOptions: {
				loggerCallback,
				logLevel: LogLevel.Info,
				piiLoggingEnabled: false,
			},
		},
	});
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
	const protectedResourceMap = new Map<string, Array<string>>();
	// protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']); // Prod environment. Uncomment to use.
	protectedResourceMap.set("https://graph.microsoft-ppe.com/v1.0/me", [
		"user.read",
	]);

	protectedResourceMap.set(`${environment.urlBackEndOpenCloseEvents}/*`, [
		"user.read",
	]);

	protectedResourceMap.set(`${environment.urlBackEndAlarms}/*`, [
		"user.read",
	]);

	// protectedResourceMap.set(`${window.location.origin}/*`, ["user.read"]);

	return {
		interactionType: InteractionType.Redirect,
		protectedResourceMap,
	};
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
	return {
		interactionType: InteractionType.Redirect,
		authRequest: {
			scopes: ["user.read"],
		},
		loginFailedRoute: AZURE_PATH_LOGIN_FAILURE,
	};
}
