import { environment } from "src/environments/environment";

export const PREFIX_PRIVATE_ROUTES = "app";
export const PATH_PREFIX = `/${PREFIX_PRIVATE_ROUTES}`;
/**
 *
 * NOMBRES DE RUTAS PARA MODULOS
 *
 * **/
export const SLUG_LOGIN = "login";
export const SLUG_PROVIDER = "proveedor";
export const SLUG_OPEN_CLOSE_EVENTS = "eventos";

/**
 * REDIRECCIONES
 */
export const PATH_REDIRECT_TO = `/${PREFIX_PRIVATE_ROUTES}/${SLUG_PROVIDER}`;
export const PATH_ROUTE_LOGIN = `/${SLUG_LOGIN}`;


/**
 * RUTAS PARA AZURE
 */
export const AZURE_URI_REDIRECT_TO             = `${environment.urlClientApp}${PATH_REDIRECT_TO}`;
export const AZURE_URI_POST_LOGOUT_REDIRECT_TO = `${environment.urlClientApp}/${SLUG_LOGIN}`;
export const AZURE_PATH_LOGIN_FAILURE          = PATH_ROUTE_LOGIN;
