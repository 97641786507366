import { BrowserModule } from "@angular/platform-browser";
import {
	NgxMatDatetimePickerModule,
	NgxMatNativeDateModule,
	NgxMatTimepickerModule,
} from "@angular-material-components/datetime-picker";

import { NgModule } from "@angular/core";

import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { authInterceptorProviders } from "@app/core/helpers/auth.interceptor";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatNativeDateModule } from "@angular/material/core";

import "./routes";
import { LayoutLoggedInComponent } from "./layout-loggedin/layout-loggedin.component";

// azure
import {
	MsalBroadcastService,
	MsalGuard,
	MsalInterceptor,
	MsalModule,
	MsalRedirectComponent,
	MsalService,
	MSAL_GUARD_CONFIG,
	MSAL_INSTANCE,
	MSAL_INTERCEPTOR_CONFIG,
} from "@azure/msal-angular";
import {
	MSALGuardConfigFactory,
	MSALInstanceFactory,
	MSALInterceptorConfigFactory,
} from "./azure-ad";
import { registerLocaleData } from "@angular/common";
import localeEs from "@angular/common/locales/es";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatSelectModule } from "@angular/material/select";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { HotToastModule } from "@ngneat/hot-toast";
import { impersonatingInterceptorProviders } from "./opening-closing-events/core/helpers/impersonating.interceptor";

registerLocaleData(localeEs, "es");

const providersAzure = [
	{
		provide: HTTP_INTERCEPTORS,
		useClass: MsalInterceptor,
		multi: true,
	},
	{
		provide: MSAL_INSTANCE,
		useFactory: MSALInstanceFactory,
	},
	{
		provide: MSAL_GUARD_CONFIG,
		useFactory: MSALGuardConfigFactory,
	},
	{
		provide: MSAL_INTERCEPTOR_CONFIG,
		useFactory: MSALInterceptorConfigFactory,
	},
	MsalService,
	MsalGuard,
	MsalBroadcastService,
];

@NgModule({
	declarations: [AppComponent, LayoutLoggedInComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		AppRoutingModule,
		FlexLayoutModule,
		NgxMatDatetimePickerModule,
		NgxMatTimepickerModule,
		MatNativeDateModule,
		NgxMatNativeDateModule,
		MsalModule,
		MatSnackBarModule,
		MatTooltipModule,
		MatSelectModule,
		MatAutocompleteModule,
		HotToastModule.forRoot(),
	],
	providers: [authInterceptorProviders, impersonatingInterceptorProviders, ...providersAzure],
	bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule { }
