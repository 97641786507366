import {
	HTTP_INTERCEPTORS,
	HttpEvent,
	HttpHeaders,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
	HttpInterceptor,
	HttpHandler,
	HttpRequest,
} from "@angular/common/http";

import { ImpersonatingStorageService } from '../services/impersonation-storage.service';
import { Observable } from "rxjs";
import { OpeningClosingEventsModule } from "@app/opening-closing-events/opening-closing-events.module";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: 'root' })
export class ImpersonatingInterceptor implements HttpInterceptor {
	constructor(
		private eventImpersonatingService: ImpersonatingStorageService,
	) { }
	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		let impersonatingReq = req;
		let impersonated = this.eventImpersonatingService.getImpersonatingMail();
		const req_path = req['url'];
		const close_path = environment.urlBackEndOpenCloseEvents;
		if (req_path.includes(close_path)) {
			// if (req_path.includes(close_path) && impersonated != '' ) {
			impersonatingReq = req.clone({
				headers: new HttpHeaders({
					// "Impersonated": "proveedores.n2@ipt.pe",
					"Impersonated": impersonated,
				}),
			});
		}
		return next.handle(impersonatingReq);
	}
}

export const impersonatingInterceptorProviders = [
	{ provide: HTTP_INTERCEPTORS, useClass: ImpersonatingInterceptor, multi: true },
];
