import {
	HTTP_INTERCEPTORS,
	HttpEvent,
	HttpHeaders,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
	HttpInterceptor,
	HttpHandler,
	HttpRequest,
} from "@angular/common/http";

import { TokenStorageService } from "../services/token-storage.service";
import { Observable } from "rxjs";

const TOKEN_HEADER_KEY = "Authorization"; // for Spring Boot back-end

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(private token: TokenStorageService) {}

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		let authReq = req;
		const token = this.token.getToken();
		if (token != null) {
			authReq = req.clone({
				// headers: req.headers.set(TOKEN_HEADER_KEY, "Bearer " + token),
				headers: new HttpHeaders({
					[TOKEN_HEADER_KEY]: "Bearer " + token,
					// "ngrok-skip-browser-warning": "69420",
				}),
			});
		}
		return next.handle(authReq);
	}
}

export const authInterceptorProviders = [
	{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];
