import { Injectable } from "@angular/core";

const AUTH_TOKEN = "auth-token";
const REFRESH_TOKEN = "refresh-token";
const USER_KEY = "user-key";

@Injectable({
	providedIn: "root",
})
export class TokenStorageService {
	constructor() {}

	public signOut(): void {
		window.localStorage.clear();
	}

	public saveToken(token: string): void {
		window.localStorage.removeItem(AUTH_TOKEN);
		window.localStorage.setItem(AUTH_TOKEN, token);
	}

	public saveRefreshToken(token: string): void {
		window.localStorage.removeItem(REFRESH_TOKEN);
		window.localStorage.setItem(REFRESH_TOKEN, token);
	}

	public getToken(): string | null {
		return window.localStorage.getItem(AUTH_TOKEN) || "";
	}

	public getRefreshToken(): string | null {
		return window.localStorage.getItem(REFRESH_TOKEN) || "";
	}

	public saveUser(user: any): void {
		window.localStorage.removeItem(USER_KEY);
		window.localStorage.setItem(USER_KEY, JSON.stringify(user));
	}

	public getUser(): any {
		const user = window.localStorage.getItem(USER_KEY);
		if (user) {
			return JSON.parse(user);
		}

		return {};
	}

}
