import { Component, OnInit } from "@angular/core";
import {
	MsalBroadcastService,
	MsalService,
	MsalGuard,
} from "@azure/msal-angular";
import { filter } from "rxjs/operators";
import {
	AuthenticationResult,
	EventMessage,
	EventType,
} from "@azure/msal-browser";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
	isIframe = false;

	constructor(
		private msalGuard: MsalGuard,
		private msalService: MsalService,
		private msalBroadcastService: MsalBroadcastService,
		private iconRegistry: MatIconRegistry,
		private sanitizer: DomSanitizer
	) {}

	ngOnInit() {
		this.msalBroadcastService.msalSubject$
			.pipe(
				filter(
					(msg: EventMessage) =>
						msg.eventType === EventType.LOGIN_SUCCESS
				)
			)
			.subscribe((result: EventMessage) => {
				const payload = result.payload as AuthenticationResult;
				this.msalService.instance.setActiveAccount(payload.account);
			});

		this.addCustomIcons();
	}

	private addCustomIcons(): void {
		this.registerSvgIcon(
			"p-arrow-chevron-right",
			"./../../../../assets/icons/chevron-right.svg"
		);
	}

	private registerSvgIcon(name: string, relativePath: string) {
		this.iconRegistry.addSvgIcon(
			name,
			this.sanitizer.bypassSecurityTrustResourceUrl(relativePath)
		);
	}
}
